import { useState, useEffect, useContext } from "react"
import { ThemeToggle } from "../../common"
import Logo from "../../common/Logo"
import MobileLogo from "../../common/MobileLogo"
import "./home.scss"
import { ThemeContext } from "../../../contexts"

const email = "ved.doog-yrev@hajile"
const subject = `Let's%20make%20something%20very%20good!`
const body = `This%20is%20your%20opportunity%20to%20let%20our%20team%20know%20what%20you%20want%20to%20build!%20Send%20us%20an%20email%20and%20fill%20us%20in%20on%20your%20big%20ideas,%20and%20we%20will%20take%20it%20from%20there🤝`

export const Home = () => {
  const [isHuman, setIsHuman] = useState(false)
  const { theme } = useContext(ThemeContext)

  const bodyEle = document.querySelector("body")

  useEffect(() => {
    if (theme === "dark") {
      bodyEle.classList.add("dark")
    }

    if (theme === "light") {
      bodyEle.classList.remove("dark")
    }
  }, [theme, bodyEle])

  return (
    <div
      className={
        "flex flex-col min-h-screen items-center justify-center  text-white"
      }
    >
      <div className="md:pl-6 animate-slide-right">
        <h1 className="hidden mb-6 text-xl font-semibold text-center uppercase lg:block">
          Let's create something
        </h1>
      </div>
      <MobileLogo />
      <Logo />
      <button
        onClick={() => {
          window.open(
            'https://calendly.com/verygooddev',
            '_blank' // <- This is what makes it open in a new window.
          )
        }}
        className={`text-lg dark:bg-darkButton bg-gray-300 min-w-[10rem] rounded-lg p-4 mb-4 animate-fade-in  ${isHuman
            ? "transition text-white duration-500 bg-primary-800 hover:bg-primary-900 hover:dark:bg-darkButtonHover cursor-pointer"
            : "text-gray-200 dark:bg-gray-300 bg-gray-300"
          }`}
        disabled={!isHuman}
        id="work"
      >
        Get in touch{" "}
        <p className='ml-1 waving-hand'>{isHuman && <p className="animate-fade-in">👋</p>}</p>
      </button>
      <div className="flex items-center justify-between animate-fade-in">
        <label className="mr-2 text-white" for="humanity_checker">
          {" "}
          Are you human?
        </label>
        <input
          className="animate-fade-in"
          type="checkbox"
          id="humanity_checker"
          name="humanity_checker"
          value="false"
          onChange={(event) => {
            setIsHuman(event.target.checked)
          }}
        ></input>
      </div>
      <ThemeToggle />
    </div>
  )
}
