export default function Logo() {
  return (
    <img
      src="VERYGOOD_TYPEFACE_01.png"
      width={500}
      height="auto"
      alt="Very Good Development Logo"
      className="hidden mb-6 lg:block animate-slide-left animate-fade-in"
    />
  )
}
