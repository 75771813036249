import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Home, Pricing, NotFound } from "./components/pages"
import { SiteSettingsProvider, ThemeProvider } from "./contexts"
import { ThemeToggle } from "./components/common"
import { isMobile } from "react-device-detect"

function App() {
  return (
    <div className={`w-screen h-screen dark:bg-color-dark bg-color-primary-500 ${
      isMobile && "dark:bg-dark bg-primary-500"
    }`}>
    <ThemeProvider>
      <SiteSettingsProvider>
        <BrowserRouter basename={""}>
          <ThemeToggle />
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/pricing"} element={<Pricing />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </SiteSettingsProvider>
    </ThemeProvider>
    </div>
  )
}

export default App
