export default function Logo() {
  return (
    <img
      src="VERYGOOD_BRANDING_01.png"
      width={250}
      height="auto"
      alt="Very Good Development Logo"
      className="block lg:hidden animate-slide-left animate-fade-in"
    />
  )
}
